import NotifyUser from 'app/actionCreators/NotifyUser';
import * as errorReporter from 'app/errorReporter';
import * as store from 'app/store';
import { getSessionUser } from 'app/stateHelpers';
import { toastError } from '@/react-ui';
const CLIENT_VERSION = '2023120115'; // year month day current-qlab-version#
const CLIENT_VERSION_STRING = '2023-12-01.1-v5';

// Fetch failure messages are different in different browsers
const fetchFailedMessages = [
  'Failed to fetch', // Chrome
  'Load failed', // Safari
  'NetworkError when attempting to fetch resource.', // Firefox
];

// We delay dispatching SIGN_IN_SUCCESS in order to mitigate an issue where
// requests immediately following a successful sign in will fail, because
// the requests require the user to be signed in, but the backend doesn't
// recognize the session as authenticated yet. This doesn't seem to affect
// sign in via password.
export const LOGIN_DELAY_MS = 750;
function ENV_HEADERS() {
  return {
    F53App: 'Web',
    F53AppVersion: CLIENT_VERSION,
    F53AppVersionString: CLIENT_VERSION_STRING,
    F53AppEnv: ENV.BUILD_ENV,
  };
}
let csrfToken: string | undefined | null = null;
type RequestOptions = {
  requireSessionUser?: boolean;
};
const defaultRequestOptions: RequestOptions = {
  requireSessionUser: false,
};
class InFlightRequest {
  id: string;
  abortController: AbortController;
  options: RequestOptions;
  constructor(
    id: string,
    abortController: AbortController,
    options?: RequestOptions,
  ) {
    this.id = id;
    this.abortController = abortController;
    this.options = options || defaultRequestOptions;
  }
  abort() {
    this.abortController.abort();
  }
}
const nonSessionInitRequests: {
  [x: string]: InFlightRequest;
} = {};
function registerInFlightRequest(
  resourceIdentifier: string,
  abortController: AbortController,
  options?: RequestOptions,
): string {
  const requestID = `${resourceIdentifier}+${Date.now()}`;
  nonSessionInitRequests[requestID] = new InFlightRequest(
    requestID,
    abortController,
    options,
  );
  return requestID;
}
function unregisterInFlightRequest(requestID) {
  delete nonSessionInitRequests[requestID];
}
export function abortNonSessionInitRequests() {
  Object.keys(nonSessionInitRequests).forEach((requestID) => {
    const request = nonSessionInitRequests[requestID];
    request.abort();
    unregisterInFlightRequest(requestID);
  });
}
export function abortSessionUserDependentRequests() {
  Object.keys(nonSessionInitRequests)
    .map((requestID) => {
      return nonSessionInitRequests[requestID];
    })
    .filter((request) => {
      return request.options.requireSessionUser;
    })
    .forEach((request) => {
      request.abort();
      unregisterInFlightRequest(request.id);
    });
}
type Args = {
  [key: string | number]: any;
};
type Filter = {
  [key: string]: any;
};
const FALLBACK_ENDPOINT = 'https://staging-accounts.figure53.com/api/v1/web';
const CONNECTION_DELAY = ENV.FIGURE_53_BACKEND_CONNECTION_DELAY
  ? parseInt(ENV.FIGURE_53_BACKEND_CONNECTION_DELAY)
  : null;
function apiEndpoint(): string {
  return ENV.FIGURE_53_API_ENDPOINT || FALLBACK_ENDPOINT;
}
type Response = {
  statusText: string;
  status: number;
  url: string;
  ok: boolean;
};
export class AbortedRequestError extends Error {
  context: {
    [key: string]: any;
  };
  constructor() {
    super('Request aborted');
    this.name = 'AbortedRequestError';
  }
}
class BackendError extends Error {
  context: {
    [key: string]: any;
  };
  constructor(response: Response) {
    super(response.statusText);
    this.name = 'BackendError';
    this.context = {
      response: {
        statusText: response.statusText,
        status: response.status,
        url: response.url,
        ok: response.ok,
      },
    };
  }
}
class BackendConnectionFailedError extends Error {
  context: {
    [key: string]: any;
  };
  constructor(args: {
    message: string;
    requestBody?: string;
    endpoint: string;
    headers: string;
  }) {
    const { message, requestBody, endpoint, headers } = args;
    super(message);
    this.context = {
      requestBody,
      endpoint,
      headers,
    };
  }
}
export class NoSessionUserError extends Error {
  thrownAtPath: string = '';
  constructor(...args: any) {
    super(...args);
    this.name = 'NoSessionUserError';
    this.thrownAtPath = window.location.pathname;
  }
}
export function restApiEndpoint(resource: string) {
  return `${apiEndpoint()}/rest/${resource}`;
}
export function REST(
  method: 'GET' | 'POST',
  resource: string,
  options?: {
    requireSessionUser?: boolean;
  },
): Promise<any> {
  const abortController = new AbortController();
  const requestID = registerInFlightRequest(
    restApiEndpoint(resource),
    abortController,
    options,
  );
  return Promise.resolve()
    .then(() => {
      if (options && options.requireSessionUser) {
        return requireSessionUser();
      }
      return initCSRFTokenAndSession();
    })
    .then(slowConnectionSimulator)
    .then(() => {
      errorReporter.setContext({
        passedCSRF: true,
        dispatch: resource,
      });
      const headers = Object.assign({}, ENV_HEADERS(), {
        Accept: 'application/json',
        'Access-Control-Request-Headers': 'X-CSRF-Token',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken || '',
      });

      return fetch(restApiEndpoint(resource), {
        signal: abortController.signal,
        method,
        mode: 'cors',
        credentials: 'include',
        // required for CSRF mitigation
        headers,
      }).catch((err) => {
        if (fetchFailedMessages.includes(err.message)) {
          throw new BackendConnectionFailedError({
            message: `Failed to fetch '${restApiEndpoint(resource)}'`,
            endpoint: restApiEndpoint(resource),
            headers: JSON.stringify(headers),
          });
        }
        throw err;
      });
    })
    .then(updateCSRFTokenFromResponse)
    .then((response) => {
      if (!response.ok) {
        throw new BackendError(response);
      }
      return response.json();
    })
    .catch(handleRESTFailed)
    .then((result) => {
      unregisterInFlightRequest(requestID);
      return result;
    });
}
export function dispatch(
  action: string,
  args?: Args | null,
  filter?: Filter | null,
  options?: {
    requireSessionUser?: boolean;
  },
): Promise<any> {
  const abortController = new AbortController();
  const requestID = registerInFlightRequest(
    apiEndpoint() + action,
    abortController,
    options,
  );
  return Promise.resolve()
    .then(() => {
      if (options && options.requireSessionUser) {
        return requireSessionUser();
      }
      return initCSRFTokenAndSession();
    })
    .then(slowConnectionSimulator)
    .then(() => {
      errorReporter.setContext({
        passedCSRF: true,
        dispatch: action,
      });
      if (args == null) {
        args = {};
      }
      if (filter == null) {
        filter = {};
      }
      const body = {
        Action: action,
        Arguments: args,
        Filter: filter,
        App: 'Web',
        AppVersion: CLIENT_VERSION,
        AppEnv: ENV.BUILD_ENV,
      };
      const headers = Object.assign({}, ENV_HEADERS(), {
        Accept: 'application/json',
        'Access-Control-Request-Headers': 'X-CSRF-Token',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken || '',
      });
      return fetch(apiEndpoint(), {
        signal: abortController.signal,
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        // required for CSRF mitigation
        body: JSON.stringify(body),
        headers,
      }).catch((err) => {
        if (fetchFailedMessages.includes(err.message)) {
          throw new BackendConnectionFailedError({
            message: `Connection failed while dispatching '${body.Action}'`,
            requestBody: JSON.stringify(body),
            endpoint: apiEndpoint(),
            headers: JSON.stringify(headers),
          });
        }
        throw err;
      });
    })
    .then(updateCSRFTokenFromResponse)
    .then((response) => {
      if (!response.ok) {
        throw new BackendError(response);
      }
      return response.json();
    })
    .catch(handleDispatchFailed)
    .then((result) => {
      unregisterInFlightRequest(requestID);
      switch (result.ErrorType) {
        case 'ServerDownForMaintenance':
          store.dispatch({
            type: 'INITIALIZE_SESSION_FAIL',
            message: result.Error,
          });
          return {
            Success: false,
            Error: result.Error,
            Result: null,
          };
        case 'ClientVersionError':
          NotifyUser({
            message:
              'This web page is out of date. Please reload your browser.',
            bad: true,
          });
          return {
            Success: false,
            Error: 'This web page is out of date.',
            Result: null,
          };
        case 'ClientEnvError':
          errorReporter.notify(
            `Server at ${apiEndpoint()} rejected connection from env: '${ENV.BUILD_ENV}'`,
          );
          return {
            Success: false,
            Error: 'There was a problem connecting to our servers.',
            Result: null,
          };
      }
      return result;
    });
}
let _initCSRFTokenAndSession = null;
function sessionEndpoint() {
  return `${apiEndpoint()}/rest/session`;
}
export function initCSRFTokenAndSession(): Promise<any> {
  if (!_initCSRFTokenAndSession) {
    errorReporter.setContext({
      passedCSRF: false,
      dispatch: `${apiEndpoint()}/rest/session`,
    });
    const headers = Object.assign({}, ENV_HEADERS(), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    _initCSRFTokenAndSession = fetch(sessionEndpoint(), {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      // required for CSRF mitigation
      headers,
    })
      .catch((err) => {
        if (fetchFailedMessages.includes(err.message)) {
          throw new BackendConnectionFailedError({
            message: `Failed to fetch '${sessionEndpoint()}`,
            endpoint: sessionEndpoint(),
            headers: JSON.stringify(headers),
          });
        }
        throw err;
      })
      .then(updateCSRFTokenFromResponse)
      .then((response) => {
        if (!response.ok) {
          throw new BackendError(response);
        }
        return response.json();
      })
      .then((response) => {
        if (response.User) {
          store.dispatch({
            type: 'SIGN_IN_SUCCESS',
            user: response.User,
            authenticatingUser: response.AuthenticatingUser,
          });
        }
        store.dispatch({
          type: 'SESSION_INITIALIZED',
        });
        return response;
      })
      .catch((err) => {
        store.dispatch({
          type: 'INITIALIZE_SESSION_FAIL',
        });
        throw err;
      });
  }
  return _initCSRFTokenAndSession;
}
function requireSessionUser(): Promise<void> {
  return initCSRFTokenAndSession().then(() => {
    if (getSessionUser(store.currentState()) == null) {
      throw new NoSessionUserError();
    }
  });
}
function updateCSRFTokenFromResponse(response) {
  return Promise.resolve().then(() => {
    csrfToken = response.headers.get('X-CSRF-Token');
    return response;
  });
}

// since REST requests don't have a well-known response structure, we
// handle these by throwing an error, rather than returning a struct
// with an error message.
function handleRESTFailed(err: Error): any {
  // handle aborted requests e.g. requests for user data were mid flight
  // when the user decided to sign out
  if (err instanceof window.DOMException && err.name === 'AbortError') {
    throw new AbortedRequestError();
  }

  // these should be handled higher up in the stack,
  // where the requireSessionUser option is specified
  if (err instanceof NoSessionUserError) {
    throw err;
  }

  // handle errors due to requests that the backend responded to as "not ok"
  if (err instanceof BackendError) {
    const { statusText } = err.context.response;
    if (statusText === 'Unauthorized') {
      throw new NoSessionUserError();
    }
    errorReporter.notify(err, {
      context: err.context,
    });
    throw err;
  }

  // Handle connection failures - requests that never made it to a backend.
  // NB: https://github.com/Figure53/websites/issues/1276
  if (err instanceof BackendConnectionFailedError) {
    // Don't report to honeybadger
    toastError(
      'There was a problem connecting to our servers. Please contact support@figure53.com if you need help.',
      { duration: Infinity, dismissible: true },
    );

    return;
  }

  // some other kind of error, originating in third party or native browser code
  errorReporter.notify(err);
  throw err;
}

function handleDispatchFailed(err: Error): any {
  if (err instanceof window.DOMException && err.name === 'AbortError') {
    throw new AbortedRequestError();
  }

  // these should be handled higher up in the stack,
  // where the requireSessionUser option is specified
  if (err instanceof NoSessionUserError) {
    throw err;
  }

  // handle errors due to requests that the backend responded to as "not ok"
  if (err instanceof BackendError) {
    const { statusText } = err.context.response;
    if (statusText === 'Unauthorized') {
      return {
        Success: false,
        Error: 'There was a problem connecting to our servers (401)',
      };
    }
    errorReporter.notify(err, {
      context: err.context,
    });
    return {
      Success: false,
      Error: err.message,
    };
  }

  // Handle connection failures - requests that never made it to a backend.
  // NB: https://github.com/Figure53/websites/issues/1276
  if (err instanceof BackendConnectionFailedError) {
    // Don't report to honeybadger
    toastError(
      'There was a problem connecting to our servers. Please contact support@figure53.com if you need help.',
      { duration: Infinity, dismissible: true },
    );

    return {
      Success: false,
      Error: 'There was a problem connecting to our servers.',
    };
  }

  // some other kind of error, originating in third party or native browser code
  errorReporter.notify(err);
  return {
    Success: false,
    Error: err.message,
  };
}
export function resetBackendSession() {
  _initCSRFTokenAndSession = null;
}
function slowConnectionSimulator(result) {
  return new Promise((resolve) => {
    if (CONNECTION_DELAY == null) {
      return resolve(result);
    }
    setTimeout(() => {
      resolve(result);
    }, CONNECTION_DELAY);
  });
}
export default {
  LOGIN_DELAY_MS,
  REST,
  dispatch,
  NoSessionUserError,
  resetSession: resetBackendSession,
  abortNonSessionInitRequests,
  abortSessionUserDependentRequests,
};
