import React, { ReactNode } from 'react';
import { Button } from './button';
import { Github } from 'lucide-react';

const Item = ({ title, content }: { title: string; content: ReactNode }) => (
  <div className="flex flex-col gap-2">
    <h5 className="opacity-50">{title}</h5>
    <div className="flex items-center gap-2">{content}</div>
  </div>
);

const Code = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  >,
) => <code className="rounded px-2 py-1 bg-white/10 inline-block" {...props} />;

export const ManifestPage = ({
  site,
  buildEnv,
  revision,
  branch,
}: {
  site?: string;
  buildEnv?: 'production' | 'staging' | 'development' | 'test';
  revision?: string;
  branch?: string;
}) => (
  <div className="bg-slate-800 h-screen font-mono">
    <div className="max-w-2xl m-auto text-white p-10 flex flex-col gap-5">
      <Item title="Website" content={site} />
      {buildEnv && <Item title="Build Env" content={buildEnv} />}
      {branch && <Item title="Branch" content={branch} />}
      {revision && <Item title="Revision" content={<Code>{revision}</Code>} />}
    </div>
  </div>
);
