import * as React from 'react';
import { createRoot } from 'react-dom/client';
import copyToClipboard from 'copy-to-clipboard';
import { Check, Link } from 'lucide-react';

/**
 * Inject a button component to all headings with an ID to copy a direct link to
 * that heading to the clipboard.
 */
export function mountAnchorLinksInElement(
  element: HTMLElement,
  textColor: 'light' | 'dark',
) {
  element.querySelectorAll('h1,h2,h3,h4,h5,h6').forEach((heading) => {
    if (!heading.id) {
      return;
    }

    const container = document.createElement('span');
    // Add container
    heading.appendChild(container);
    // Add tw classes
    heading.classList.add('group');
    heading.classList.add('relative');

    const root = createRoot(container);
    root.render(<AnchorLink textColor={textColor} anchor={heading.id} />);
  });
}

type Props = {
  textColor: 'light' | 'dark';
  anchor: string;
};

const textColorMap = {
  light: 'text-white',
  dark: 'text-slate-900',
};

/**
 * A button to copy a link to the current page with a given fragment to the
 * clipboard.
 */
export const AnchorLink = (props: Props) => {
  const { textColor, anchor } = props;
  const [copied, setCopied] = React.useState(false);
  const copiedTimoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  const copyURL = (event: React.MouseEvent) => {
    copyToClipboard(
      `${window.location.origin}${window.location.pathname}#${anchor}`,
    );
    setCopied(true);

    if (copiedTimoutRef.current) {
      clearTimeout(copiedTimoutRef.current);
    }

    copiedTimoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 3000);

    event.preventDefault();

    return false;
  };

  React.useEffect(() => {
    return () => {
      if (copiedTimoutRef.current) {
        clearTimeout(copiedTimoutRef.current);
      }
    };
  }, []);

  return (
    <button
      className={`inline-flex absolute left-[-26px] text-sm top-0 cursor-copy h-full flex items-start pt-1 opacity-0 group-hover:opacity-75 transition-all`}
      onClick={copyURL}
      aria-hidden
    >
      {copied ? (
        <Check className="text-green-500" size={20} />
      ) : (
        <Link className={textColorMap[textColor]} size={20} />
      )}
    </button>
  );
};
